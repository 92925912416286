import React from 'react'
import AlairConfImagesNineteen from '../../../components/AlairConfImagesNineteen'
import H1 from '../../../components/H1'
import Layout from '../../../components/Layout'

const acp2019 = () => (
  <Layout>
    <H1 title="ALAIR Conference Photos 2019" />
    <AlairConfImagesNineteen />
  </Layout>
)
export default acp2019
