import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Lightbox from './Lightbox'

const AlairConfImagesNineteen = () => (
  <StaticQuery
    query={graphql`
      query {
        confNineteenImages: allFile(
          filter: { sourceInstanceName: { eq: "confNineteenImages" } }
        ) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }
    `}
    render={data => <Lightbox images={data.confNineteenImages.edges} />}
  />
)
export default AlairConfImagesNineteen

// import React from 'react'
// import { ReactRpg } from 'react-rpg'
// import Wrapper from '../../../components/Wrapper'
// import Anran_and_Michelle_on_Merry_Go_Round from './Anran_and_Michelle_on_Merry_Go_Round.jpg'
// import Bonfire from './Bonfire.jpg'
// import Bridge_18Jan2019 from './Bridge_18Jan2019.jpg'
// import DGE2 from './DGE2.jpg'
// import Deer from './Deer.jpg'
// import DonkeyGoats_Emu from './DonkeyGoats_Emu.jpg'
// import Donkeys5Goats2_9Mar2019 from './Donkeys5Goats2_9Mar2019.jpg'
// import DonkeysTouchBEST_9Mar2019 from './DonkeysTouchBEST_9Mar2019.jpg'
// import Donkeys_6Mar2019 from './Donkeys_6Mar2019.jpg'
// import GuntBalc from './GuntBalc.jpg'
// import GuntBalcony from './GuntBalcony.jpg'
// import GuntFlash from './GuntFlash.jpg'
// import Guntersville_View_with_Flash from './Guntersville_View_with_Flash.jpg'
// import IMG957015 from './IMG957015.jpg'
// import Julie_Snake from './Julie_Snake.jpg'
// import LodgeRmViewMorn_7mar2019 from './LodgeRmViewMorn_7mar2019.jpg'
// import LodgeRoomViewDeer_7Mar2019 from './LodgeRoomViewDeer_7Mar2019.jpg'
// import Lodge_Hospitality_6Mar2019 from './Lodge_Hospitality_6Mar2019.jpg'
// import NatureHikeWendyMattie5 from './NatureHikeWendyMattie5.jpg'
// import NatureHike_7Mar2019 from './NatureHike_7Mar2019.jpg'
// import PavWendyMelissa_18Jan2019 from './PavWendyMelissa_18Jan2019.jpg'
// import PavWendySomer_18Jan2019 from './PavWendySomer_18Jan2019.jpg'
// import Pavilion from './Pavilion.jpg'
// import SnakeCotton_Valerie7Mar2019 from './Snake-Cotton_Valerie_7Mar2019.jpg'
// import SnakeJohnfromSAS from './Snake-John_from_SAS.jpg'
// import Zip2 from './Zip2.jpg'

// class Images extends React.Component {
//   constructor(props) {
//     super(props)

//     this.state = {
//       padding: 10,
//       columns: 3,
//       popUp: false,
//     }
//   }
//   columnsChanged(e) {
//     this.setState({ columns: parseInt(e.target.value, 10) })
//   }

//   /**
//    * A generic callback function to be executed upon click of the image wrapper.
//    * @param url - The URL prop of the object
//    * @param obj - The syntheticMouseEvent object via react.
//    */
//   imagePopup(url, obj) {
//     this.setState({ popUp: url })
//   }
//   closeImagePopup(url, obj) {
//     this.setState({ popUp: false })
//   }

//   getImages() {
//     return [
//       {
//         url: Anran_and_Michelle_on_Merry_Go_Round,
//         clickHandler: this.imagePopup.bind(this),
//       },
//       { url: Bonfire, clickHandler: this.imagePopup.bind(this) },
//       { url: Bridge_18Jan2019, clickHandler: this.imagePopup.bind(this) },
//       { url: DGE2, clickHandler: this.imagePopup.bind(this) },
//       { url: Deer, clickHandler: this.imagePopup.bind(this) },
//       { url: DonkeyGoats_Emu, clickHandler: this.imagePopup.bind(this) },
//       {
//         url: Donkeys5Goats2_9Mar2019,
//         clickHandler: this.imagePopup.bind(this),
//       },
//       {
//         url: DonkeysTouchBEST_9Mar2019,
//         clickHandler: this.imagePopup.bind(this),
//       },
//       { url: Donkeys_6Mar2019, clickHandler: this.imagePopup.bind(this) },
//       { url: GuntBalc, clickHandler: this.imagePopup.bind(this) },
//       { url: GuntBalcony, clickHandler: this.imagePopup.bind(this) },
//       { url: GuntFlash, clickHandler: this.imagePopup.bind(this) },
//       {
//         url: Guntersville_View_with_Flash,
//         clickHandler: this.imagePopup.bind(this),
//       },
//       { url: IMG957015, clickHandler: this.imagePopup.bind(this) },
//       { url: Julie_Snake, clickHandler: this.imagePopup.bind(this) },
//       {
//         url: LodgeRmViewMorn_7mar2019,
//         clickHandler: this.imagePopup.bind(this),
//       },
//       {
//         url: LodgeRoomViewDeer_7Mar2019,
//         clickHandler: this.imagePopup.bind(this),
//       },
//       {
//         url: Lodge_Hospitality_6Mar2019,
//         clickHandler: this.imagePopup.bind(this),
//       },
//       { url: NatureHikeWendyMattie5, clickHandler: this.imagePopup.bind(this) },
//       { url: NatureHike_7Mar2019, clickHandler: this.imagePopup.bind(this) },
//       {
//         url: PavWendyMelissa_18Jan2019,
//         clickHandler: this.imagePopup.bind(this),
//       },
//       {
//         url: PavWendySomer_18Jan2019,
//         clickHandler: this.imagePopup.bind(this),
//       },
//       { url: Pavilion, clickHandler: this.imagePopup.bind(this) },
//       {
//         url: SnakeCotton_Valerie7Mar2019,
//         clickHandler: this.imagePopup.bind(this),
//       },
//       { url: SnakeJohnfromSAS, clickHandler: this.imagePopup.bind(this) },
//       { url: Zip2, clickHandler: this.imagePopup.bind(this) },
//     ]
//   }

//   render() {
//     return (
//       <Wrapper>
//         <div className="demo">
//           <h1>ALAIR Conference 2019 Photos</h1>
//           <hr />
//           {/* <input type="range" min="1" max="5" className="columsController" value={this.state.columns} onChange={this.columnsChanged.bind(this)} /> */}

//           {this.state.popUp !== false ? (
//             <img
//               onClick={this.closeImagePopup.bind(this)}
//               src={this.state.popUp}
//               style={{
//                 top: '50%',
//                 left: '50%',
//                 transform: 'translate(-50%, -50%)',
//                 position: 'fixed',
//                 border: 'solid #1a1a1a 10px',
//                 zIndex: '3',
//               }}
//               alt={this.state.popUp}
//               width="50%"
//               height="auto"
//             />
//           ) : null}
// {/*
//           <ReactRpg
//             imagesArray={this.getImages()}
//             columns={[1, 2, this.state.columns]}
//             padding={this.state.padding}
//           /> */}
//           <p> </p>
//         </div>
//       </Wrapper>
//     )
//   }
// }

// export default Images
